import React from 'react';
import BookNow from './BookNow';

const Rooms = () => {
    return (
        <div>
            <BookNow />
        </div>
    )
}

export default Rooms;
